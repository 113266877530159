import request from '@/utils/request';

import { loginUser } from './login/loginAPI';
import * as blogAPI from './blog/blogAPI';
import * as userAPI from './user/user';
import * as userRoleAPI from './user/role';
import * as hospitalAPI from './user/hospital';

export default {
  loginUser,
  blogAPI,
  userAPI,
  userRoleAPI,
  hospitalAPI,
};

/**
 * @description: 获取广西下的市级数据
 * @return {*}
 */
export const getAreaByCode = () => {
  return request.get('/gov/area/children?parent_area_code=450000000000');
};
