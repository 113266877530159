<!-- 官网：https://element-plus.org/zh-CN/component/select.html -->
<template>
  <el-select
    v-model="selectValue"
    :placeholder="placeholder"
    no-data-text="暂无数据"
    no-match-text="暂未找到相关数据"
    v-bind="$attrs"
  >
    <!-- 自定义头部s -->
    <template #header v-if="showHeader">
      <slot name="header"></slot>
    </template>
    <!-- 自定义头部e -->

    <el-option
      :value="item[prop.value]"
      :label="item[prop.label]"
      :disabled="item.disabled"
      v-for="(item, index) in options"
      :key="index"
    >
      <!-- 自定义渲染每个选项模板 -->
      <slot name="item" :item="item"></slot>
    </el-option>

    <!-- 自定义底部s -->
    <template #footer v-if="showFooter">
      <slot name="footer"></slot>
    </template>
    <!-- 自定义底部e -->
  </el-select>
</template>

<script setup lang="ts" name="Select">
import { ref } from 'vue';

interface IOption {
  [key: string]: any;
}

interface IProp {
  label: string;
  value: string;
}

const props = defineProps({
  modelValue: { required: true, type: String || Number || null, default: null },

  options: { required: true, type: Array as () => IOption, default: () => [] },

  // 选项的label和value所对应的key名
  prop: {
    required: true,
    type: Object as () => IProp,
    default: () => {
      return { label: 'label', value: 'id' };
    },
  },

  placeholder: { type: String, default: '请选择' },

  showHeader: { type: Boolean, default: false },

  showFooter: { type: Boolean, default: false },
});

const selectValue = ref(props.modelValue);
</script>

<style scoped></style>
