/*
 * @Description: 存储被激活过的路由
 * @Date: 2024-09-14 11:31:19
 * @LastEditTime: 2024-09-23 14:48:35
 * @FilePath: \dog-vue3\src\store\modules\activitedRouter.ts
 */
interface IState {
  routes: Array<any>;
  current: string;
}

const state: IState = {
  routes: [],
  current: '', // 当前激活项
};

const mutations = {
  SET_ROUTES(state: IState, data: any[]) {
    state.routes = data;
  },

  ADD_ROUTE(state: IState, route: { path: string; title: string }) {
    // 防止重复添加相同的菜单项
    if (!state.routes.some((item) => item.path === route.path)) {
      state.routes.push(route);
    }
    state.current = route.path;
  },

  REMOVE_ROUTE(state: IState, path: string) {
    state.routes = state.routes.filter((item) => item.path !== path);
  },

  set_current(state: IState, data: string) {
    state.current = data;
  },
};

const getters = {
  getDatas(state: IState) {
    return state.routes;
  },
  getCurrent(state: IState) {
    return state.current;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
};
