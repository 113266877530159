import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AdminHeader = _resolveComponent("AdminHeader")!
  const _component_Aside = _resolveComponent("Aside")!
  const _component_Main = _resolveComponent("Main")!
  const _component_el_container = _resolveComponent("el-container")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createBlock(_component_el_container, { direction: "vertical" }, {
    default: _withCtx(() => [
      _createVNode(_component_AdminHeader),
      _createVNode(_component_el_container, null, {
        default: _withCtx(() => [
          _createVNode(_component_Aside),
          _createVNode(_component_el_container, { direction: "vertical" }, {
            default: _withCtx(() => [
              _createVNode(_component_Main)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_Footer)
    ]),
    _: 1
  }))
}