<template>
  <el-upload
    :headers="headers"
    :name="name"
    :accept="accept"
    :limit="limit"
    :disabled="disabled"
    list-type="picture-card"
    action="#"
    :before-upload="beforeUpload"
    v-model:file-list="fileList"
  >
    <el-icon><Plus /></el-icon>

    <template #file="{ file }">
      <div>
        <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />

        <span class="el-upload-list__item-actions">
          <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
            <el-icon><zoom-in /></el-icon>
          </span>
          <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove(file)">
            <el-icon><Delete /></el-icon>
          </span>
        </span>
      </div>
    </template>
  </el-upload>

  <el-dialog v-model="dialogVisible">
    <img w-full :src="dialogImageUrl" alt="图片预览" />
  </el-dialog>
</template>

<script setup lang="ts" name="UploadImg">
import { UploadFile, UploadFiles, UploadRawFile } from 'element-plus/es/components/upload/src/upload';
import { Delete, Plus, ZoomIn } from '@element-plus/icons-vue';
import { ElMessage, ElMessageBox } from 'element-plus';
import { ref } from 'vue';

const props = defineProps({
  accept: { type: String, default: '.png,.jpeg' },

  disabled: { type: Boolean, default: false },

  limit: { type: Number, default: 1 },
});

const headers = ref({ Authorization: 'Authorization' });

// 上传的文件字段名
const name = ref('file');

const fileList = ref<any[]>([]);

const dialogVisible = ref<boolean>(false);

const dialogImageUrl = ref('');

const beforeUpload = (rawFile: UploadRawFile) => {
  const fileExtension = rawFile.name.substring(rawFile.name.lastIndexOf('.'));
  const accepts = props.accept.split(',');
  if (accepts.includes(fileExtension)) {
    console.log('文件通过');
    fileList.value.push(rawFile);
  } else {
    ElMessage.warning('上传的文件类型不符合');
    return false;
  }
};

const handleRemove = (file: UploadFile) => {
  ElMessageBox.confirm(`确定删除${file.name}吗？`, '提示')
    .then(() => {
      const index = fileList.value.findIndex((item) => item.uid === file.uid);
      if (index !== -1) {
        fileList.value.splice(index, 1);
      }

      // 调用删除接口
    })
    .catch(() => {
      console.log('取消了删除');
    });
};

const handlePictureCardPreview = (file: UploadFile) => {
  dialogImageUrl.value = file.url!;
  dialogVisible.value = true;
};
</script>

<style scoped></style>
