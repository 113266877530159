/*
 * @Description:
 * @Date: 2024-09-23 11:42:37
 * @LastEditTime: 2024-12-29 20:56:51
 * @FilePath: \dog-management-vue\src\api\user\user.ts
 */
import request from '@/utils/request';

//#region modal定义
export enum ERole {
  admin = 'ADMIN',
  lawyer = 'LAWYER',
  guest = 'GUEST',
}

interface Page {
  page: number;
  num: number;
}

export class passwdModal {
  tel: string = '';
  new_password: string = '';
  re_new_password: string = '';
}

export class createUserModal {
  // tel: string = '';
  // password: string = '';
  // re_password: string = '';
  phone: string = '';
  role: ERole | null = null;
}

export class queryUserModal implements Page {
  tel: string = '';
  hospital_name: string = '';
  page: number = 1;
  num: number = 30;
}

export class userOriganizeModal {
  tel: string = '';
  hospital_id: number = 0;
}
//#endregion

// =================================================================================================
// ===========================================分割线:以下是api=======================================
export const getUserList = (data: queryUserModal) => {
  return request.get('/get_all_user_by_list_2', {
    params: data,
  });
};

export const deleteUser = (id: number) => {
  return request.delete('/delete_user?id=' + id);
};

/**
 * @description: 更新密码
 * @param {any} data
 * @return {*}
 */
export const updateUserPassword = (data: passwdModal) => {
  const params = new URLSearchParams({
    tel: data.tel,
    new_password: data.new_password,
    re_new_password: data.re_new_password,
  });
  return request.post(`/update_passwd?${params.toString()}`);
};

/**
 * @description: 新增用户
 * @param {createUserModal} data
 * @return {*}
 */
export const createUserByAdmin = (data: createUserModal) => {
  return request.post(
    '/admin/create/user',
    data
    // '/create_user_from_admin?tel=' +
    // data.tel +
    // '&password=' +
    // data.password +
    // '&re_password=' +
    // data.re_password +
    // '&role=' +
    // data.role
  );
};

// 给用户绑定机构
export const addUserOriganize = (data: userOriganizeModal) => {
  return request.post('/update/user_hospital', data);
};
