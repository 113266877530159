<!-- 官网：https://element-plus.org/zh-CN/component/table.html -->
<template>
  <el-table
    ref="MTable"
    border
    :stripe="stripe"
    :max-height="maxHeight"
    :data="dataList"
    :row-key="(row:any) => row.id"
    v-bind="$attrs"
    v-loading="loading"
    style="width: 100%"
  >
    <slot name="selector">
      <el-table-column prop="selection" type="selection" width="50" v-if="hasC" align="center" />
    </slot>
    <el-table-column type="index" label="序号" width="70" v-if="hasNO" align="center">
      <template #default="scope">{{ (currentPage - 1) * pageSize + scope.$index + 1 }}</template>
    </el-table-column>
    <slot></slot>

    <template #empty>
      <el-empty description="暂无数据" :image-size="120"></el-empty>
    </template>
  </el-table>
  <!-- 分页s -->
  <template v-if="isPage">
    <el-pagination
      v-model:current-page="currentPage"
      v-model:page-size="pageSize"
      :page-sizes="[30, 50, 100, 200]"
      :disabled="disabled"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </template>
  <!-- 分页e -->
</template>

<script setup lang="ts">
import { ref } from 'vue';

defineProps({
  dataList: { type: Array, default: () => [] },
  // 表格使用斑马模式
  stripe: { type: Boolean, default: true },
  // 序号
  hasNO: { type: Boolean, default: true },
  // 是否可选择
  hasC: { type: Boolean, default: false },
  // 表格最大高度
  maxHeight: { type: Number, default: 600 },
  // 是否显示分页
  isPage: { type: Boolean, default: true },
  // 总条数
  total: { type: Number, default: 0 },
  // 页码是否可用
  disabled: { type: Boolean, default: false },
  loading: { type: Boolean, default: false },
});

const emitEvents = defineEmits(['size-change']);

const currentPage = ref(1);

const pageSize = ref(30);

const handleSizeChange = (value: number) => {
  pageSize.value = value;
  emitEvents('size-change', pageSize.value, currentPage.value);
};

const handleCurrentChange = (value: number) => {
  currentPage.value = value;
  emitEvents('size-change', pageSize.value, currentPage.value);
};
</script>

<style scoped></style>
