/*
 * @Description:
 * @Date: 2024-09-23 11:28:34
 * @LastEditTime: 2024-12-29 16:06:00
 * @FilePath: \dog-management-vue\src\utils\type.ts
 */
export interface IRole {
  superadmin?: 'SUPERADMIN';
  admin?: 'ADMIN';
  lawer?: 'LAWYER';
  hospital?: 'HOSPITAL';
  owner?: 'OWNER';
  guest?: 'GUEST';
}

export enum ERole {
  superadmin = 'SUPERADMIN',
  admin = 'ADMIN',
  lawyer = 'LAWYER',
  hospital = 'HOSPITAL',
  owner = 'OWNER',
  guest = 'GUEST',
}

export const roleMap: Record<string, string> = {
  guest: '普通用户',
  owner: '犬主',
  hospital: '宠物医院',
  lawyer: '执法员',
  admin: '管理员',
  superadmin: '超管',
};

// export interface blogCategory {
//   notice: '政策通知';
//   question: '常见问题';
// }

export enum EBlogCategory {
  question = '养犬贴士', //养犬贴士
  notice = '政策通知', //通知公告
  default = '默认',
}
