<template>
  <el-footer class="mc-foot">
    <div>{{ title }} {{ copyright }} {{ company }}</div>
    <div class="link" @click="linkToRecord">{{ record }}</div>
  </el-footer>
</template>

<script setup lang="ts">
const title = process.env.VUE_APP_TITLE;
const copyright = process.env.VUE_APP_COPYRIGHT;
const company = process.env.VUE_APP_US;
const record = process.env.VUE_APP_RECORD;

const linkToRecord = () => {
  window.open('https://beian.miit.gov.cn/', '_blank');
};
</script>

<style scoped>
.mc-foot {
  line-height: var(--el-footer-height);
  text-align: center;
  padding: 0;
  background-color: #f8f8f8;
  border-top: 1px solid #efeded;
  display: flex;
  justify-content: center;
}

.mc-foot .link {
  margin-left: 10px;
  cursor: pointer;
}
</style>
