import request from '@/utils/request';
import { EBlogCategory } from '@/utils/type';

interface Page {
  page: number;
  num: number;
}

export class Query implements Page {
  category: EBlogCategory | null = EBlogCategory.default;
  page: number = 1;
  num: number = 30;
}

export class CreateBlogModal {
  title: string = '';
  author: string = 'Admin';
  category: EBlogCategory = EBlogCategory.notice;
  content: string = '';
}

export interface BlogRes {
  title: string;
  author: string;
  category: string;
  content: string;
  created_at: string;
  updated_at: string;
}

export interface UpdateBlogModal {
  title: string;
  content: string;
}

// =================================================================================================
// ===========================================分割线:以下是api=======================================

// 添加文章
export const createBlog = (par: CreateBlogModal) => {
  return request.post('/create_blog', par);
};

// 删除文章
export const deleteBlog = (id: number) => {
  return request.delete('/delete_blog?blog_id=' + id);
};

// 获取单条文章
export const getBlogById = (blog_id: number) => {
  return request.get(`/get_blogs/${blog_id}`);
};

// 获取文章分页列表
export const getBlogList = (pageObj: Page) => {
  // return request.get(`/get_list_blog?page=${pageObj.page}&num=${pageObj.num}`);
  return request.get('/get_list_blog', {
    params: {
      page: pageObj.page,
      num: pageObj.num,
    },
  });
};

// 更新文章
export const updateBlogById = (blog_id: number, par: UpdateBlogModal) => {
  return request.put(`/update_blog?blog_id=` + blog_id, par);
};

// 按类别获取分页列表
export const getBlogListByCategory = (data: Query) => {
  return request.get(`/get_category`, {
    params: {
      category: data.category,
      page: data.page,
      num: data.num,
    },
  });
};
