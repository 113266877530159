<!-- 官网：https://element-plus.org/zh-CN/component/radio.html -->
<template>
  <el-radio-group v-model="radioValue" @change="changeValue" :disabled="disabled" :size="size">
    <!-- 按钮样式s -->
    <template v-if="isButton">
      <el-radio-button :value="item[prop.value]" v-for="(item, index) in options" :key="index">{{
        item[prop.label]
      }}</el-radio-button>
    </template>
    <!-- 按钮样式e -->

    <template v-else>
      <el-radio :value="item[prop.value]" v-for="(item, index) in options" :key="index" :border="border">{{
        item[prop.label]
      }}</el-radio>
    </template>
  </el-radio-group>
</template>

<!-- SFC有两个scope -->
<!-- setup scope -->
<script setup lang="ts" name="RadioGroup">
import { ref, PropType } from 'vue';

interface IOption {
  [key: string]: any;
}

interface IProp {
  label: string;
  value: string;
}

const props = defineProps({
  modelValue: { required: true, type: String || Number || Boolean || null, default: '' },
  // 选项
  options: { required: true, type: Array as () => IOption, default: () => [] },
  // 选项的label和value所对应的key名
  prop: {
    required: true,
    type: Object as () => IProp,
    default: () => {
      return { label: 'label', value: 'id' };
    },
  },
  // 是否按钮模式
  isButton: { type: Boolean, default: false },
  // 非按钮模式下是否有边框
  border: { type: Boolean, default: false },
  // 是否禁用
  disabled: { type: Boolean, default: false },
  // 大小(enum类型)：large small default
  size: {
    type: String as PropType<RadioGroupSize>,
    default: RadioGroupSize.Default,
    validator: (value: string) => Object.values(RadioGroupSize).includes(value as RadioGroupSize),
  },
});

const emits = defineEmits(['update:modelValue']);

const radioValue = ref(props.modelValue);

const changeValue = (value: string | number | boolean | undefined) => {
  if (value !== undefined) {
    emits('update:modelValue', value);
  } else {
    emits('update:modelValue', '');
  }
};
</script>

<!-- module scope -->
<script lang="ts">
enum RadioGroupSize {
  Default = 'default',
  Small = 'small',
  Large = 'large',
}
export default {};
</script>

<style scoped></style>
