/*
 * @Description:
 * @Date: 2024-09-21 22:42:58
 * @LastEditTime: 2024-12-18 09:29:27
 * @FilePath: \dog-management-vue\src\store\modules\user.ts
 */
import { ActionContext } from 'vuex';
import api from '@/api/index';
import { IRole } from '@/utils/type';

interface UserState {
  expireTime: number | null;
  userName: string | null;
  role: IRole | null;
}

const initialState: UserState = {
  expireTime: null,
  userName: null,
  role: {},
};

const mutations = {
  setToken(state: UserState, data: string) {
    window.sessionStorage.setItem('xtoken', data);
  },

  removeToken() {
    window.sessionStorage.removeItem('xtoken');
  },

  setExpireTime(state: UserState, expireTime: number | null) {
    state.expireTime = expireTime;
  },

  setUser(state: UserState, userData: any) {
    state.userName = userData.username;
    state.role = userData.role;
  },

  clearUser(state: UserState) {
    state.userName = null;
    state.role = {};
  },

  logout(state: UserState) {
    state.expireTime = null;
    state.userName = null;
    state.role = {};
    window.sessionStorage.removeItem('xtoken');
    window.sessionStorage.removeItem('user'); // 清除 sessionStorage 中的持久化数据
  },
};

const actions = {
  async login(context: ActionContext<UserState, any>, params: any) {
    if (!params.username) {
      return Promise.reject(new Error('请输入用户名'));
    }

    if (!params.password) {
      return Promise.reject(new Error('请输入密码'));
    }

    try {
      const res: any = await api.loginUser(params);
      context.commit('setUser', res);
      context.commit('setToken', res.XTOKEN);
      const expireTime = new Date().getTime() + 2 * 60 * 60 * 1000;
      context.commit('setExpireTime', expireTime);
      return Promise.resolve(res);
    } catch (error: any) {
      return Promise.reject(error);
    }
  },
};

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
};
