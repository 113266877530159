/*
 * @Description:
 * @Date: 2024-09-30 19:25:20
 * @LastEditTime: 2024-12-29 19:07:09
 * @FilePath: \dog-management-vue\src\api\user\hospital.ts
 */
import request from '@/utils/request';
import qs from 'qs';

export class Page {
  page: number = 1;
  num: number = 30;
}

export class queryModal {
  name: string = '';
  // 1:医院  2:乡镇
  type: number = 1;
}

export class createHospitalModal {
  phones: string[] = [];
  name: string = '';
  address: string = '';
  area_code: number = 450100000000;
  working_hours: string = '';
  assignee: string = '';
  type: number = 1;
}

export interface updateHospitalModal {
  name: string;
  address?: string;
  area_code?: number;
  working_hours?: string;
  tel_add?: string[];
  tel_del?: string[];
}

// =================================================================================================
// ===========================================分割线:以下是api=======================================

/**
 * @description: 添加许可的医院
 * @param {createHospitalModal} data
 * @return {*}
 */
export const createHospital = (data: createHospitalModal) => {
  return request.post('/add_hospitals_access', data);
};

/**
 * @description: 分页列表
 * @param {queryModal} data
 * @return {*}
 */
export const getList = (data: queryModal, params: Page) => {
  return request.post('/get_hospitals', data, {
    params: params,
  });
};

/**
 * @description: 获取单条数据
 * @param {number} hid
 * @return {*}
 */
export const getHospitalById = (hid: number) => {
  return request.get(`/hospital/${hid}`);
};

export const deleteHospital = (name: string) => {
  return request.delete('/delete_hospital?name=' + name);
};

export const updateHospital = (params: updateHospitalModal) => {
  // 构建查询参数
  const queryParams: Record<string, string | string[]> = {
    name: params.name,
  };

  if (params.tel_add) {
    queryParams.tel_add = params.tel_add;
  } else if (params.tel_del) {
    queryParams.tel_del = params.tel_del;
  } else if (params.address) {
    queryParams.address = params.address;
  }

  return request.post(
    '/update_hospitalinfo',
    queryParams
    // {},
    // {
    //   params: queryParams,
    //   paramsSerializer: (params) => qs.stringify(params),
    // }
  );
};
