<template>
  <el-upload
    v-model:file-list="fileList"
    name="file"
    :accept="accept"
    :multiple="multiple"
    :limit="limit"
    :disabled="disabled"
    :headers="headers"
    :data="extraData"
    :show-file-list="showFileList"
    action="https://run.mocky.io/v3/9d059bf9-4660-45f2-925d-ce80ad6c4d15"
    :before-upload="beforeUpload"
    :on-exceed="handleExceed"
    :before-remove="beforeRemove"
    :on-remove="handleRemove"
    :on-success="handleSuccess"
  >
    <!-- 上传文件列表s -->
    <el-button type="primary">上传</el-button>

    <template #tip v-if="tips">
      <div class="el-upload__tip">{{ tipTxt }}</div>
    </template>
    <!-- 上传文件列表e -->
  </el-upload>
</template>

<script setup lang="ts" name="Upload">
import { ElMessage, ElMessageBox } from 'element-plus';
import { UploadFile, UploadFiles, UploadRawFile, UploadUserFile } from 'element-plus/es/components/upload/src/upload';
import { ref } from 'vue';

const props = defineProps({
  accept: { type: String, default: '.doc,.docx,.pdf' },

  disabled: { type: Boolean, default: false },

  multiple: { type: Boolean, default: false },

  limit: { type: Number, default: 1 },

  // 	是否显示已上传文件列表
  showFileList: { type: Boolean, default: true },

  actionUrl: { required: true, type: String, default: '' },

  // 是否显示tip提示
  tips: { type: Boolean, default: false },

  tipTxt: { type: String, default: '' },

  // 上传时附带的额外参数
  extraData: { type: Object },
});

const headers = ref({ Authorization: 'UserModule.' });

// 上传的文件字段名
// const name = ref('file');

const fileList = ref<any[]>([]);

// 上传文件前的钩子函数
const beforeUpload = (rawFile: UploadRawFile) => {
  const fileExtension = rawFile.name.substring(rawFile.name.lastIndexOf('.'));
  const accepts = props.accept.split(',');
  if (accepts.includes(fileExtension)) {
    console.log('文件通过');
  } else {
    ElMessage.warning('上传的文件类型不符合');
    return false;
  }
};

// 删除文件之前的钩子
const beforeRemove = (file: UploadFile, uploadFiles: UploadFiles) => {
  return new Promise<boolean>((resolve, reject) => {
    ElMessageBox.confirm(`确定删除${file.name}吗？`, '提示')
      .then(() => {
        resolve(true);
      })
      .catch(() => {
        reject(false);
      });
  });
};

const handleRemove = (uploadFile: UploadFile, uploadFiles: UploadFiles): void => {};

const handleSuccess = () => {};

const handleExceed = (files: File[], uploadFiles: UploadUserFile[]): void => {
  ElMessage.warning(`当前限制选择 ${props.limit} 个文件，本次选择了 ${files.length} 个文件`);
};
</script>

<style scoped></style>
