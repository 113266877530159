import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate'; // 持久化插件
import userModule from './modules/user';
import activitedRoutesModule from './modules/activitedRouter';

const persistedStateConfig = {
  key: 'user',
  storage: window.sessionStorage,
  reducer: (state: any) => ({
    user: {
      userName: state.user.userName,
      role: state.user.role,
      expireTime: state.user.expireTime,
    },
  }),
};

export default createStore({
  modules: {
    user: userModule,
    activatedRoutes: activitedRoutesModule,
  },
  plugins: [createPersistedState(persistedStateConfig)],
});
