<!--
 * @Description: 
 * @Date: 2024-09-21 22:42:58
 * @LastEditTime: 2024-09-30 20:48:02
 * @FilePath: \dog-vue3\src\layouts\components\Main.vue
-->
<template>
  <el-main class="mc-main">
    <el-tabs v-model="activeTab" @tab-remove="handleRemove" @tab-change="handleChange">
      <el-tab-pane
        :label="item.title"
        :name="item.path"
        :closable="activeTab !== item.path"
        v-for="item in storeRoutes"
        :key="item.path"
      />
    </el-tabs>

    <router-view></router-view>
  </el-main>
</template>

<script setup lang="ts">
import { computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

const store = useStore();

const router = useRouter();

const storeRoutes = computed(() => store.getters['activatedRoutes/getDatas']);

const activeTab = computed({
  get() {
    return store.getters['activatedRoutes/getCurrent'];
  },
  set(value) {
    store.commit('activatedRoutes/set_current', value);
  },
});

onMounted(() => {});

const handleRemove = (name: any): void => {
  store.commit('activatedRoutes/REMOVE_ROUTE', name);
};

const handleChange = (name: any): void => {
  router.push(name);
  store.commit('activatedRoutes/set_current', name);
};
</script>

<style scoped lang="less">
:deep(.el-tabs__item) {
  padding: 0 20px;
}

:deep(.el-tabs--top .el-tabs__item.is-top:nth-child(2)) {
  padding-left: 20px;
}

:deep(.el-tabs--top .el-tabs__item.is-top:last-child) {
  padding-right: 20px;
}

:deep(.el-tabs__active-bar) {
  display: none;
}

:deep(.el-tabs__item.is-active) {
  background-color: var(--el-menu-hover-bg-color);
  border-bottom: 2px solid;
}

.mc-main {
  min-height: 800px;
  padding: 0 20px;
}
</style>
