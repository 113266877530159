<template>
  <el-container direction="vertical">
    <AdminHeader></AdminHeader>

    <el-container>
      <Aside></Aside>
      <el-container direction="vertical">
        <Main></Main>
      </el-container>
    </el-container>

    <Footer></Footer>
  </el-container>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import AdminHeader from './components/AdminHeader.vue';
import Aside from './components/Aside.vue';
import Footer from './components/Footer.vue';
import Main from './components/Main.vue';

export default defineComponent({
  components: { AdminHeader, Aside, Footer, Main },
  setup() {
    return {};
  },
});
</script>

<style scoped lang="less"></style>
