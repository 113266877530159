<!--
 * @Description: 广西的市级选择器
 * @Date: 2024-11-19 09:43:22
 * @LastEditTime: 2024-11-19 10:32:41
 * @FilePath: \dog-vue3\src\components\select-city\SelectCity.vue
-->
<template>
  <el-select
    v-model="selectValue"
    :placeholder="placeholder"
    no-data-text="暂无数据"
    no-match-text="暂未找到相关数据"
    v-bind="$attrs"
    @change="changeValue"
    @clear="clearValue"
  >
    <el-option :value="item.area_code" :label="item.name" v-for="(item, index) in options" :key="index"> </el-option>
  </el-select>
</template>

<script setup lang="ts">
import { ElMessage } from 'element-plus';
import { onMounted, ref } from 'vue';
import { getAreaByCode } from '@/api/index';

const props = defineProps({
  placeholder: { type: String, default: '请选择' },

  modelValue: { required: true, type: Number || null, default: null },
});

const emitEvents = defineEmits(['update:modelValue']);

const selectValue = ref<number | null>(props.modelValue);

const options = ref<any[]>([]);

const getAreaData = async () => {
  try {
    const res: any = await getAreaByCode();
    options.value = res;
  } catch (error: any) {
    ElMessage.error(error.message);
  }
};

const changeValue = (value: any): void => {
  emitEvents('update:modelValue', value);
};

const clearValue = (): void => {
  emitEvents('update:modelValue', null);
};

onMounted(() => {
  getAreaData();
});
</script>

<style scoped></style>
