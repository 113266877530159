<template>
  <el-select
    v-model="selectValue"
    filterable
    remote
    remote-show-suffix
    value-key="id"
    :remote-method="remoteMethod"
    :loading="loading"
    no-data-text="暂无数据"
    no-match-text="暂未找到相关数据"
    :clearable="clearable"
    @change="changeValue"
  >
    <el-option v-for="item in options" :key="item[prop.value]" :label="item[prop.label]" :value="item[prop.value]">
      <!-- 自定义渲染每个选项模板 -->
      <slot name="item" :item="item"></slot>
    </el-option>
  </el-select>
</template>

<!-- module scope -->
<script lang="ts">
enum SelectorSize {
  Default = 'default',
  Small = 'small',
  Large = 'large',
}
export default {};
</script>

<script setup lang="ts" name="SelectSearch">
import { ref, PropType } from 'vue';

interface IProp {
  label: string;
  value: string;
}

const props = defineProps({
  modelValue: { required: true, type: String || Number || null, default: null },

  prop: {
    required: true,

    type: Object as () => IProp,

    default: () => {
      return { label: 'label', value: 'id' };
    },
  },

  size: {
    type: String as PropType<SelectorSize>,
    default: SelectorSize.Default,
    validator: (value: string) => Object.values(SelectorSize).includes(value as SelectorSize),
  },

  // 是否可以清空选项
  clearable: { type: Boolean, default: true },

  // 远程搜索所调用的接口
  fn: { type: Function, default: () => {} },
});

const emitEvents = defineEmits(['update:modelValue']);

const loading = ref(false);

const options = ref<any[]>([]);

const selectValue = ref(props.modelValue);

const remoteMethod = () => {};

const changeValue = (value: any) => {
  emitEvents('update:modelValue', value);
};
</script>

<style scoped></style>
