import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
import zhCn from 'element-plus/es/locale/lang/zh-cn';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'; //FontAwesomeIcon组件
import '@/utils/fontAwesomeIcon';

import '@/assets/index.css'; //global css

import components from '@/components/index'; //global components

const app = createApp(App);

app.use(store);
app.use(router);
app.use(ElementPlus, { size: 'default', zIndex: 3000, locale: zhCn });
app.use(components);
app.component('font-awesome-icon', FontAwesomeIcon);
app.mount('#app');

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}
