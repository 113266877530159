import request from '@/utils/request';
import { ERole } from './user';

export class roleModal {
  tel: string = '';
  role: ERole | null | string = null;
}

/**
 * @description: 给用户增加角色
 * @param {roleModal} data
 * @return {*}
 */
export const addRoleToUser = (data: roleModal) => {
  return request.post('/add_userlevel_from_admin', null, {
    params: data,
  });
};

/**
 * @description: 删除用户的角色
 * @param {roleModal} data
 * @return {*}
 */
export const deleteRoleForUser = (data: roleModal) => {
  return request.delete(`/delete_userlevel_from_admin?tel=${data.tel}&role=${data.role}`);
};
