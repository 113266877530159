<template>
  <el-aside :width="asideWidth" class="mc-transition" :class="{ 'mc-transition-out': isCollapse }">
    <el-menu
      :default-active="activeMenu"
      class="el-menu-vertical-demo"
      router
      :collapse="isCollapse"
      :unique-opened="false"
      :class="{ 'active-collapse-transition': isCollapse }"
    >
      <div class="collapse-icon">
        <el-icon class="icon" v-if="!isCollapse" @click="collapseHandle" title="收起">
          <Fold />
        </el-icon>
        <el-icon class="icon" v-else @click="collapseHandle" title="展开">
          <Expand />
        </el-icon>
      </div>

      <!-- 路由菜单start -->
      <template v-for="(item, index) in dynamicRoutes" :key="index">
        <template v-if="!item.children">
          <el-menu-item
            :index="item.path"
            @click="onMenuItem($event, item.meta.title)"
            v-show="checkRole(item.meta.has)"
          >
            <component class="icons" :is="item.meta.icon"></component><span>{{ item.meta.title }}</span>
          </el-menu-item>
        </template>

        <!-- 子菜单start -->
        <template v-else>
          <el-sub-menu :index="item.path" v-show="checkRole(item.meta.has)">
            <template #title>
              <component class="icons" :is="item.meta.icon"></component>
              <span>{{ item.meta.title }}</span>
            </template>
            <el-menu-item
              v-for="(e, ind) in item.children"
              :key="ind"
              :index="e.path"
              @click="onMenuItem($event, e.meta.title)"
            >
              <component class="icons" :is="e.meta.icon"></component>
              {{ e.meta.title }}
            </el-menu-item>
          </el-sub-menu>
        </template>
        <!-- 子菜单end -->
      </template>
      <!-- 路由菜单end -->
    </el-menu>
  </el-aside>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import dynamicRoutes from '@/router/routes/routes';
import { useRoute } from 'vue-router';
import { MenuItemRegistered } from 'element-plus/es/components/menu/src/types';
import { useStore } from 'vuex';
import { ERole } from '@/utils/type';

const store = useStore();

const asideWidth = ref<string>('240px');

const isCollapse = ref<boolean>(false);

const activeMenu = ref<string>('');

// 更改tab标签页上的路由后，左边的导航栏同步更改当前项
const currentRoutes = computed(() => store.getters['activatedRoutes/getCurrent']);
watch(currentRoutes, (v: string) => {
  if (v) {
    activeMenu.value = v;
  }
});

onMounted(() => {
  const { path, meta } = useRoute(); // 获取当前路径
  activeMenu.value = path;

  store.commit('activatedRoutes/SET_ROUTES', [{ path: path, title: meta?.title }]);
  store.commit('activatedRoutes/set_current', path);
});

/**
 * @description: 检查当前用户是否可见菜单
 * @param {*} roles
 * @return {*} boolean
 */
const checkRole = (roles: ERole[] | undefined): boolean => {
  // undefined 则是默认不显示
  if (!roles) return false;

  // 获取用户的所有角色
  const userRoles = Object.values(store.state.user.role)
    .filter(Boolean)
    .map((role) => role as any);

  // 检查用户的角色是否包含在给定的角色列表中
  return userRoles.some((userRole) => roles.includes(userRole));
};

const collapseHandle = () => {
  if (isCollapse.value) {
    asideWidth.value = '240px';
  } else {
    // calc(var(--el-menu-icon-width) + var(--el-menu-base-level-padding)* 2) + 1px边框
    asideWidth.value = 24 + 20 * 2 + 1 + 'px';
  }

  isCollapse.value = !isCollapse.value;
};

const onMenuItem = (item: MenuItemRegistered, name: string) => {
  store.commit('activatedRoutes/ADD_ROUTE', { path: item.index, title: name });
};
</script>

<style scoped lang="less">
svg {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

:deep(.el-menu) {
  border-right: none;
  background-color: unset;
}

:deep(.el-menu-item.is-active) {
  font-weight: bold;
}

:deep(.el-menu:not(.active-collapse-transition) .el-menu-item.is-active) {
  background-color: #fff;
  transition: background-color 0.3s ease-in;
}

.mc-transition {
  transition: width 0.3s ease-in;
  background-color: #e8f2fc;
  box-sizing: border-box;
  overflow: clip;
  min-height: calc(100vh - 125px);
}

.mc-transition-out {
  transition: width 0.3s ease-in;
}

.collapse-icon {
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;

  .icon {
    cursor: pointer;
    font-size: 24px;
  }
}
</style>
