<!-- 官网：https://element-plus.org/zh-CN/component/dialog.html -->
<template>
  <el-dialog :close-on-click-modal="false" :close-on-press-escape="false" v-bind="$attrs" @close="handleClose">
    <template #footer>
      <slot name="footer">
        <div class="dialog-footer" v-if="footer">
          <el-button @click="handleCancle">取消</el-button>
          <el-button type="primary" @click="handleOk">确定</el-button>
        </div>
      </slot>
    </template>
    <div class="dialog-content" :class="{ scroll: scrollable }"><slot></slot></div>
  </el-dialog>
</template>

<script setup lang="ts" name="dialog">
const emitEvents = defineEmits(['close', 'success']);

defineProps({
  // 是否出现滚动条
  scrollable: { type: Boolean, default: false },

  // 是否显示对话框的footer
  footer: { type: Boolean, default: true },

  // 是否可以通过点击 modal 关闭 Dialog
  closeOnClickModal: { type: Boolean, default: false },
});

const handleClose = () => {
  emitEvents('close');
};
const handleCancle = () => {
  emitEvents('close');
};
const handleOk = () => {
  emitEvents('success');
};
</script>

<style scoped>
.dialog-content.scroll {
  max-height: 550px;
  overflow-y: auto;
  padding: 0 10px;
}
</style>
