<!--
 * @Description: wangeditor富文本编辑器，官网：https://www.wangeditor.com/
 * @Date: 2024-05-27 09:16:53
 * @LastEditTime: 2024-09-23 14:46:38
 * @FilePath: \dog-vue3\src\components\wangeditor\WangEditor.vue
-->
<template>
  <div style="border: 1px solid #ccc" ref="editorContainerRef">
    <Toolbar style="border-bottom: 1px solid #ccc" :editor="editorRef" :defaultConfig="toolbarConfig" :mode="mode" />
    <Editor
      style="height: 300px; overflow-y: hidden"
      v-model="valueHtml"
      :defaultConfig="editorConfig"
      :mode="mode"
      @onCreated="handleCreated"
      @onChange="handleChange"
      @customPaste="customPaste"
      @onBlur="handleBlur"
    />
  </div>
</template>

<script setup lang="ts">
import '@wangeditor/editor/dist/css/style.css'; // 引入 css
import { onBeforeUnmount, ref, shallowRef, onMounted, watch } from 'vue';
import { Editor, Toolbar } from '@wangeditor/editor-for-vue';
import { IToolbarConfig, IEditorConfig, DomEditor, IDomEditor } from '@wangeditor/editor';

const editorRef = shallowRef(); // 编辑器实例，必须用 shallowRef

const editorContainerRef = ref();

const valueHtml = ref('');

const mode = 'default'; // default或者simple

const usual = [
  'headerSelect',
  'bold', // 加粗
  'italic', // 斜体
  'underline', // 下划线
  'color', // 文字颜色
  'fontSize', // 字体大小
  'bulletedList', // 无序列表
  'numberedList', // 有序列表
  'delIndent', // 缩进
  'indent', // 增进
  'undo', // 撤销
  'redo', // 重做
  'clearStyle', // 清除格式
  'fullScreen', // 全屏
];

const defaultTool = [
  'headerSelect',
  'bold',
  'italic',
  'through',
  'underline',
  'color',
  'bgColor',
  'fontSize',
  'fontFamily',
  'lineHeight',
  'bulletedList',
  'numberedList',
  'delIndent',
  'indent',
  'justifyLeft',
  'justifyRight',
  'justifyCenter',
  'insertLink',
  'insertTable',
  'insertImage',
  'insertVideo',
  'undo',
  'redo',
  'clearStyle',
  'fullScreen',
];

const toolbarConfig: Partial<IToolbarConfig> = {
  toolbarKeys: usual,
};

const editorConfig: Partial<IEditorConfig> = {
  placeholder: '请输入...',
  readOnly: false,
};

const props = defineProps({
  modelValue: { required: true, type: String || Number || Boolean || null, default: '' },
  default: { type: Boolean, default: false },
});

const emit = defineEmits<{ (e: 'update:modelValue', value: string): void }>();

onMounted(() => {
  valueHtml.value = props.modelValue;

  if (props.default) {
    toolbarConfig.toolbarKeys = defaultTool;
  }
});

// 组件销毁时，也及时销毁编辑器
onBeforeUnmount(() => {
  const editor = editorRef.value;
  if (editor == null) return;
  editor.destroy();
});

// 监听外部model变化更新本地值
watch(
  () => props.modelValue,
  (newVal, oldValue) => {
    if (newVal !== valueHtml.value) {
      valueHtml.value = newVal;
    }
  },
  { immediate: true }
);

/**
 * @description: 编辑器创建完毕时的回调函数
 * @param {*} editor
 * @return {*}
 */
const handleCreated = (editor: any) => {
  editorRef.value = editor; // 记录 editor 实例，重要！
};

const handleChange = (editor: IDomEditor) => {
  valueHtml.value = editor.getHtml();
  emit('update:modelValue', valueHtml.value);
};

const handleBlur = (editor: IDomEditor) => {
  valueHtml.value = editor.getHtml();
  emit('update:modelValue', valueHtml.value);
};

/**
 * @description: 自定义粘贴
 * @param {*} editor
 * @param {*} event
 * @param {*} callback
 * @return {*}
 */
const customPaste = (editor: IDomEditor, event: ClipboardEvent, callback: any) => {
  // 获取粘贴的纯文本
  const text = event.clipboardData?.getData('text/plain'); //text/html
  if (text) {
    editor.insertText(text);
    event.preventDefault(); // 阻止默认的粘贴行为
    callback(false);
  }
};
</script>

<style scoped></style>
