import request from '@/utils/request';

// 登录
export const loginUser = (par: any) => {
  // return request.post('/login', null, {
  //   params: par,
  // });
  // return request({
  //   url: '/login',
  //   method: 'post',
  //   params: par,
  //   headers: { 'Content-Type': 'application/x-www-form-urlencoded' }, // 可显式设置为表单数据格式
  // });
  return request({
    url: '/login',
    method: 'post',
    data: par,
  });
};
