/*
 * @Description: https://fontawesome.com/search?o=r&m=free(不建议导入全部图标)
 * @Date: 2024-05-28 14:36:16
 * @LastEditTime: 2024-09-23 14:49:18
 * @FilePath: \dog-vue3\src\utils\fontAwesomeIcon.ts
 */
import { library } from '@fortawesome/fontawesome-svg-core';

// 常规图标
import {
  // faCopy as farCopy,
  // faNewspaper as farNewspaper,
  // faBell as farBell,
  // faImages as farImages,
  far,
} from '@fortawesome/free-regular-svg-icons';

// 实体图标
import {
  // faCopy as fasCopy,
  // faNewspaper as fasNewspaper,
  // faCircleExclamation as fasCircleExclamation,
  // faBell as fasBell,
  // faImages as fasImages,
  // faShieldDog as fasShieldDog,
  // faAnglesUp as fasAnglesUp,
  // faAnglesDown as fasAnglesDown,
  fas,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  far,
  fas
  // farCopy,
  // fasCopy,
  // farNewspaper,
  // fasNewspaper,
  // farBell,
  // fasBell,
  // fasCircleExclamation,
  // farImages,
  // fasImages,
  // fasShieldDog,
  // fasAnglesUp,
  // fasAnglesDown
);
