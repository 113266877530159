import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import AdminMainLayout from '@/layouts/MainLayout.vue';
import dynamicRoutes from './routes/routes';

const routes: Array<RouteRecordRaw> = [
  { path: '/', redirect: '/login', children: [] },
  {
    path: '/login',
    component: () => import('@/views/login/Login.vue'),
    name: 'Login',
  },
  {
    path: '/home',
    component: AdminMainLayout,
    meta: { title: '首页', icon: 'home-outlined' },
    children: [...dynamicRoutes.value],
  },
  {
    path: '/dataScreen',
    component: () => import('@/views/data-screen/index.vue'),
    name: 'DataScreen',
  },
  { path: '/', meta: { title: '', hidden: true }, redirect: '/home' },
  { path: '/404', meta: { title: '', hidden: true }, component: () => import('@/views/404.vue') },
  // *为通配符，匹配所有路径
  { path: '/:catchAll(.*)', redirect: '/404', meta: { title: '', icon: '' }, children: [] },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.path == '/login') return next();

  const token = window.sessionStorage.getItem('xtoken');
  if (token) next();
  else next({ path: '/login' });
});

export default router;
